<template lang="pug">
  include ../mixins/_mixins.pug
  div.container
    section.navbar
      .navbar__item.navbar__item-left
        span.navbar__item-btn(v-if="$root.choseTemplateView", @click="$root.hideTemplate()")
          <svg-icon name="arrow"/>
      h1.c-title {{$root.choseTemplateView ? title : "Конструктор"}}
      .navbar__item.navbar__item-right
        <router-link class="navbar__item navbar__item-right" to="/guide/" v-if="$root.window.width > 1199">
          <svg-icon name="guide"/>
          span Гид по выбору
        </router-link>
        span.navbar__item-btn(v-if="$root.window.width < 1199 && $root.choseTemplateView && $root.choseTemplateView!='price'")
          .c-constructor__questinon(@click='$modal.show("constructor_"+$root.choseModalView)')
            +icon('question')
    section.c-constructor
      template(v-if="$root.choseTemplateView === 'form'")
        .c-constructor__block
          .c-constructor__row
            label.c-constructor__item(v-for="(item, i) in $root.forms")
              input(@change="$root.choseTemplate()", :id="'form'+i", type="radio", name="form", v-model="val_form", :value="item.name")
              .c-constructor__item-body
                .c-constructor__item-img
                  <img :src="require(`@/assets/img/content/forms/${item.img}`)" alt="">
                .c-constructor__item-name {{item.name}}
      template(v-else-if="$root.choseTemplateView === 'weight'")
        .c-constructor__block
          .c-constructor__weight
            <vue-slider v-model="weight" :dotSize="29" :tooltipPlacement="'bottom'" :marks="[$root.min_weight, $root.max_weight]" :min="$root.min_weight" :max="$root.max_weight" :interval="0.01" :height="3" :process-style="{ backgroundColor: 'transparent' }" :rail-style="{ background: 'linear-gradient(77.93deg, rgba(2,2,32,0.3) 0%, #020220 100%)' }">
              <template v-slot:step="{ label, active }" v-slot:dot>
                <div :class="['custom-dot']"></div>
              </template>
            </vue-slider>
            .c-constructor__weight-input
              input(v-model="weight", type="number", :min="$root.min_weight" :max="$root.max_weight")
      template(v-else-if="$root.choseTemplateView === 'color'")
        .c-constructor__block
          .c-constructor__row
            label.c-constructor__item(v-for="(item, i) in $root.colors")
              input(@change="$root.choseTemplate()", :id="'color'+i", type="radio", name="color", v-model="val_color", :value="item.name")
              .c-constructor__item-body
                .c-constructor__item-img
                  <img :src="require(`@/assets/img/content/forms/${item.img}`)" alt="">
                .c-constructor__item-name {{item.name}}
      template(v-else-if="$root.choseTemplateView === 'clear'")
        .c-constructor__block
          .c-constructor__row
            label.c-constructor__item(v-for="(item, i) in $root.clears")
              input(@change="$root.choseTemplate()", :id="'clear'+i", type="radio", name="clear", v-model="val_clear", :value="item.name")
              .c-constructor__item-body
                .c-constructor__item-img
                  <img :src="require(`@/assets/img/content/forms/${item.img}`)" alt="">
                .c-constructor__item-name {{item.name}}
      template(v-else-if="$root.window.width < 1199")
        .c-constructor__chose
          .c-constructor__chose-item(v-for="item in $root.choses", @click="$root.choseTemplate(item.id), title=item.name")
            .c-constructor__chose-icon
              <svg-icon :name="item.img" class="icon__color-accent"/>
            .c-constructor__chose-name {{item.name}}
        .c-constructor__right
          .c-constructor__right-form
            .c-constructor__right-pic
              <img :src="require(`@/assets/img/content/forms/${val_pic}`)" alt="">
            .c-constructor__right-name {{val_form}}
          .c-constructor__right-body
              ul.c-constructor__right-char
                li.c-constructor__right-char__item
                  span.c-constructor__right-char__item-name Вес
                  span.c-constructor__right-char__item-dot
                  span.c-constructor__right-char__item-final {{weight}}
                li.c-constructor__right-char__item
                  span.c-constructor__right-char__item-name Цвет
                  span.c-constructor__right-char__item-dot
                  span.c-constructor__right-char__item-final {{val_color}}
                li.c-constructor__right-char__item
                  span.c-constructor__right-char__item-name Чистота
                  span.c-constructor__right-char__item-dot
                  span.c-constructor__right-char__item-final {{val_clear}}
              .c-constructor__right-price
                .c-constructor__right-price__title Стоимость
                .c-constructor__right-price__num {{$root.numberWithSpaces(price)}}
                  span.rub i
              .c-constructor__right-bottom
                button.c-btn.c-btn__constructor(@click='$modal.show("constructor", {val_form:val_form, weight:weight, val_color:val_color, val_clear:val_clear, val_price:price, val_pic:val_pic})')
                  .c-btn__arr.c-btn__arr-left
                    .c-btn__arr-line.c-btn__arr-line_v
                    .c-btn__arr-line.c-btn__arr-line_h
                  .c-btn__arr.c-btn__arr-right
                    .c-btn__arr-line.c-btn__arr-line_v
                    .c-btn__arr-line.c-btn__arr-line_h
                  span.c-btn__name Заказать
                //span.c-constructor__pay Оплатить сразу
      template(v-else)
        .c-constructor__block
          .c-constructor__title.c-constructor__title-question
            span Форма бриллианта
            .c-constructor__questinon(@click='$modal.show("constructor_form")')
              +icon('question')
          .c-constructor__row
            label.c-constructor__item(v-for="(item, i) in $root.forms")
              input(:id="'form'+i", type="radio", name="form", v-model="val_form", :value="item.name")
              .c-constructor__item-body
                .c-constructor__item-img
                  <img :src="require(`@/assets/img/content/forms/${item.img}`)" alt="">
                .c-constructor__item-name {{item.name}}
        .c-constructor__inner
          .c-constructor__left
            .c-constructor__block
              .c-constructor__title.c-constructor__title-question
                span Вес в каратах
                .c-constructor__questinon(@click='$modal.show("constructor_weight")')
                  +icon('question')
              .c-constructor__weight
                <vue-slider @error="error" v-model.number="weight" :dotSize="29" :tooltipPlacement="'bottom'" :marks="[$root.min_weight, $root.max_weight]" :min="$root.min_weight" :max="$root.max_weight" :interval="0.01" :height="3" :process-style="{ backgroundColor: 'transparent' }" :rail-style="{ background: 'linear-gradient(77.93deg, rgba(2,2,32,0.3) 0%, #020220 100%)' }">
                  <template v-slot:step="{ label, active }" v-slot:dot>
                    <div :class="['custom-dot']"></div>
                  </template>
                </vue-slider>
                .c-constructor__weight-input
                  input(v-model.number="weight", type="number", :min="$root.min_weight" :max="$root.max_weight")
            .c-constructor__block
              .c-constructor__title.c-constructor__title-question
                span Цвет
                .c-constructor__questinon(@click='$modal.show("constructor_color")')
                  +icon('question')
              .c-constructor__row
                label.c-constructor__item(v-for="(item, i) in $root.colors")
                  input(:id="'color'+i", type="radio", name="color", v-model="val_color", :value="item.name")
                  .c-constructor__item-body
                    .c-constructor__item-img
                      <img :src="require(`@/assets/img/content/forms/${item.img}`)" alt="">
                    .c-constructor__item-name {{item.name}}
            .c-constructor__block
              .c-constructor__title.c-constructor__title-question
                span Чистота
                .c-constructor__questinon(@click='$modal.show("constructor_clear")')
                  +icon('question')
              .c-constructor__row
                label.c-constructor__item(v-for="(item, i) in $root.clears")
                  input(:id="'clear'+i", type="radio", name="clear", v-model="val_clear", :value="item.name")
                  .c-constructor__item-body
                    .c-constructor__item-img
                      <img :src="require(`@/assets/img/content/forms/${item.img}`)" alt="">
                    .c-constructor__item-name {{item.name}}
          .c-constructor__right
            .c-constructor__right-form
              .c-constructor__right-pic
                <transition name="fade">
                  <img :src="require(`@/assets/img/content/forms/${val_pic}`)" alt="">
                </transition>
              <transition name="fade">
                .c-constructor__right-name {{val_form}}
              </transition>
            .c-constructor__right-body
              ul.c-constructor__right-char
                li.c-constructor__right-char__item
                  span.c-constructor__right-char__item-name Вес
                  span.c-constructor__right-char__item-dot
                  span.c-constructor__right-char__item-final {{weight}}
                li.c-constructor__right-char__item
                  span.c-constructor__right-char__item-name Цвет
                  span.c-constructor__right-char__item-dot
                  span.c-constructor__right-char__item-final {{val_color}}
                li.c-constructor__right-char__item
                  span.c-constructor__right-char__item-name Чистота
                  span.c-constructor__right-char__item-dot
                  span.c-constructor__right-char__item-final {{val_clear}}
              .c-constructor__right-price
                .c-constructor__right-price__title Стоимость
                .c-constructor__right-price__num {{$root.numberWithSpaces(price)}}
                  span.rub i
              .c-constructor__right-bottom
                button.c-btn.c-btn__constructor(@click='$modal.show("constructor", {val_form:val_form, weight:weight, val_color:val_color, val_clear:val_clear, val_price:price, val_pic:val_pic})')
                  .c-btn__arr.c-btn__arr-left
                    .c-btn__arr-line.c-btn__arr-line_v
                    .c-btn__arr-line.c-btn__arr-line_h
                  .c-btn__arr.c-btn__arr-right
                    .c-btn__arr-line.c-btn__arr-line_v
                    .c-btn__arr-line.c-btn__arr-line_h
                  span.c-btn__name Заказать
                //span.c-constructor__pay Оплатить сразу
</template>

<script>
import price_car from '@/data/price_car.json'
import price_car_round from '@/data/price_car_round.json'
import { mapState } from 'vuex'
export default {
  name: 'Constructor',
  metaInfo: {
    title: 'Заказать бриллианты ручной работы | OnlyDiamond',
    meta: [
      {
        name: 'description',
        content:
          'Изготовление бриллиантов по индивидуальным требованиям. Выбирайте форму, цвет, вес и чистоту бриллианта. Заказать бриллиант или получить консультацию +7 (921) 092-00-04',
      },
    ],
  },
  data() {
    return {
      title: 'Конструктор',
      choseTemplateView: '',
      weight: this.$root.min_weight,
      val_form: this.$root.forms[0].name,
      val_color: this.$root.colors[0].name,
      val_clear: this.$root.clears[0].name,
      price_car,
      price_car_round,
    }
  },
  computed: {
    ...mapState('catalog', {
      currencyUsd: state => state.currencyUsd,
    }),
    val_pic: function() {
      const result = this.$root.forms.filter(x => x.name == this.val_form)
      return result.length ? result[0].img : ''
    },
    color: function() {
      const result = this.$root.colors.filter(x => x.name == this.val_color)
      return result.length ? result[0].id : ''
    },
    clear: function() {
      const result = this.$root.clears.filter(x => x.name == this.val_clear)
      return result.length ? result[0].id : ''
    },
    karat: function() {
      return this.weight
    },
    form: function() {
      return this.val_form
    },
    price: function() {
      // console.log(`usd:${this.currencyUsd}`)
      localStorage.setItem('usd', this.currencyUsd)
      const prices = this.form === 'Круглый' ? this.price_car_round : this.price_car
      const gap = prices.find(x => x.min <= this.karat && this.karat <= x.max)
      return Number.parseInt(
        (gap ? Math.round(gap.price[this.color][this.clear] * this.karat * this.currencyUsd) : 1) *
          1.4,
      )
    },
  },
  methods: {
    error(type) {
      switch (type) {
        case 3:
          this.weight = this.$root.min_weight
          break
        case 4:
          this.weight = this.$root.max_weight
          break
      }
    },
  },
  async created() {
    if (!this.ready) {
      await this.$store.dispatch('catalog/getCurrency')
    }
  },
}
</script>

<style>
.vue-slider {
  padding: 0;
}
</style>
